.pulse {
    transition: 0.2s ease all;
    animation: pulseAnimation 2s ease 0s infinite normal forwards;
    text-align: center;
}

.excludeFromPulse {
    animation: none !important;
    color: white;
}

@keyframes pulseAnimation {
    0%,
    100% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1);
    }
}
body {
    width: 100vw;
    overflow-x: hidden;
}

.invertedText {
    mix-blend-mode: difference;
}

.shippingVendorButton {
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid dimgrey;
    margin-right: 2px;
}

.logoImg {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 600px) {
    .logoImg {
        top: 1%;
    }
}

@media only screen and (min-width: 1280px) {
    .glassMorphCard {
        background: rgba(255, 255, 255, 0.2) !important;
        border-radius: 16px !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
        backdrop-filter: blur(5px) !important;
        -webkit-backdrop-filter: blur(5px) !important;
        border: 1px solid rgba(255, 255, 255, 0.3) !important;
    }
}